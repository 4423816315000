import React from "react";
import useThemeConfig from "src/themes/useThemeConfig";
import {FilterParams} from "@menu/utils/constants";
import useRouter from "src/core/common/hooks/useRouter";

export default function ProductFilters(props) {
  const config = useThemeConfig();
  const router = useRouter();

  const ThemedFilters = config.components.ProductFilters;
  const displayOptions = {...props.displayOptions};
  if (FilterParams.SUBCATEGORY in router.query) displayOptions.categoriesVisible = false;

  return <ThemedFilters {...props} displayOptions={displayOptions} />;
}
